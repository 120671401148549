<template>
  
  <div class="product_review">
 
    <div :class="{'seed_box': true, 'is_smoke': isSmoke}">
 
      <div class="brand">
          
        <img loading="lazy" class="lg" :src="data.item_harvest.item_review_seed.item_brand.logo_s" alt="">
        
        <div class="str_box">
          <div class="header">{{ data.item_harvest.item_review_seed.item_product.name }}</div>
          <div class="meta">
            {{ data.item_harvest.item_review_seed.item_brand.name }}
          </div>
          <div class="rate">
            <UiRating
              :cnt="10"
              :vl="data.item_harvest.item_review_seed.n_general"
              @change="data.item_harvest.item_review_seed.n_general = $event"
              :editable="true"
              />
          </div>
        </div> 
 
      </div>

      <div class="grow" v-if="!isSmoke">
        <div class="header">{{ $t('harvest_view_growing_it') }}</div>
        <div class="rbtns">    
          <div 
            v-for="(opt,key) in HarvestSeedPropsGrowingTr"
            :key="key"
            @click="handleChooseGrowing(key)"
            :class="'rbtn ' + (data.item_harvest.item_review_seed.props.growing == key ? 'active' : '')">
            {{ opt ? $t(opt) : '' }}  
          </div> 
        </div>
      </div>

      <div class="resi" v-if="!isSmoke">
        <div class="header">{{ $t('harvest_view_resistance') }}</div>
        <div class="rbtns"> 
          <div 
            v-for="(opt,key) in HarvestSeedPropsResistanceTr"
            :key="key"
            @click="handleChooseResistance(key)"
            :class="'rbtn ' + (data.item_harvest.item_review_seed.props.resistance == key ? 'active' : '')">
            {{ opt ? $t(opt) : '' }}  
          </div>  
        </div>
      </div>


    </div>

    <div class="comm">       
      <UiTextArea 
        :text="data.item_harvest.item_review_seed.text"
        @change:text="data.item_harvest.item_review_seed.text = $event"
        /> 
    </div>
 
  </div>
  
</template>

<script setup lang="ts">

import { HarvestSeedPropsGrowing, HarvestSeedPropsGrowingTr, HarvestSeedPropsResistance, HarvestSeedPropsResistanceTr } from '@/types/harvest'

interface PageProps {
  data: any;
  isSmoke?: boolean;
}

const props = defineProps<PageProps>()


const handleChooseGrowing = (vl: HarvestSeedPropsGrowing) => {
  props.data.item_harvest.item_review_seed.props.growing = vl;
}

const handleChooseResistance = (vl: HarvestSeedPropsResistance) => {
  props.data.item_harvest.item_review_seed.props.resistance = vl;
}

</script>

<style scoped>
  

.seed_box{
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.seed_box .brand{

  display: flex;
  align-items: center;
  flex-shrink: 0;
    justify-content: center;
}
.seed_box.is_smoke{

  justify-content: center;
}
.seed_box .brand .lg{
  width: 70px;
    flex-shrink: 0;
    margin-right: 15px;
}
.seed_box .grow{
  text-align: center;
}
.seed_box .resi{
  text-align: center;
}

.seed_box .header{
    font-size: 1.2rem;
    font-weight: bold;
}
.text_strain{
  margin-top: 20px;

}
.comm{
  margin-top: 1rem;
}

.rbtns{

    display: flex;
    justify-content: center;
    font-weight: bold;
    margin-top: 6px;
}
.rbtn{

    background-color: var(--un-background-color-gray);
    padding: 7px 13px;
    cursor: pointer;
}
.rbtn:first-child{
      border-radius: 5px 0 0 5px;
}
.rbtn:last-child{
      border-radius: 0 5px 5px 0;
}
.rbtn.active{

    background-color: var(--un-primary-back-color);
    color: white;
}

@container pb (max-width: 600px) {

  .text_strain{
    text-align: center;
  }
  .seed_box{
    flex-wrap: wrap;
  }

  .seed_box .brand{
      width: 100%;
  }
  .seed_box .grow{
    width: 100%;
  }
  .seed_box .resi{
    width: 100%;
  }
  .seed_box .brand .content{
      text-align: center;
      max-width: 310px;
  }
  .seed_box .brand .item{
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
  }
}

@container pb (max-width: 400px) {
  .seed_box .grow{
    width: 100%;
  }
  .seed_box .resi{
      width: 100%;
  }
}

</style>
